import { createTheme } from "@mui/material";
import AppBar from "./components/AppBar";
import Button from "./components/Button";
import CssBaseline from "./components/CssBaseline";
import DataGrid from "./components/DataGrid";
import Divider from "./components/Divider";
import Drawer from "./components/Drawer";
import FilledInput from "./components/FilledInput";
import InputAdornment from "./components/InputAdornment";
import InputBase from "./components/InputBase";
import InputLabel from "./components/InputLabel";
import Link from "./components/Link";
import ListItemButton from "./components/ListItemButton";
import ListItemIcon from "./components/ListItemIcon";
import ListItemText from "./components/ListItemText";
import Menu from "./components/Menu";
import MenuItem from "./components/MenuItem";
import OutlinedInput from "./components/OutlinedInput";
import Pagination from "./components/Pagination";
import Paper from "./components/Paper";
import Stack from "./components/Stack";
import Toolbar from "./components/Toolbar";
import palette from "./palette";
import shadows from "./shadows";
import typography from "./typography";

export const theme = createTheme({
  palette,
  typography,
  mixins: {
    toolbar: {
      minHeight: 78,
    },
  },
  components: {
    MuiAppBar: AppBar,
    MuiToolbar: Toolbar,
    MuiPaper: Paper,
    MuiDrawer: Drawer,

    MuiButton: Button,

    MuiInputBase: InputBase,
    MuiInputLabel: InputLabel,
    MuiFilledInput: FilledInput,
    MuiOutlinedInput: OutlinedInput,
    MuiInputAdornment: InputAdornment,

    MuiDivider: Divider,
    MuiStack: Stack,

    MuiLink: Link,
    MuiListItemButton: ListItemButton,
    MuiListItemIcon: ListItemIcon,
    MuiListItemText: ListItemText,

    MuiMenu: Menu,
    MuiMenuItem: MenuItem,

    MuiPagination: Pagination,
    MuiDataGrid: DataGrid,
    MuiCssBaseline: CssBaseline,
  },
});

// Add custom shadows to theme
shadows.forEach((shadow, index) => {
  theme.shadows[index + 1] = shadow;
});
