import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Button, Grid, IconButton, Toolbar } from "@mui/material";
import { TeckifyIcon } from "src/components/icons/teckify/teckify";

const navItems = ["Home", "About", "Contact"];

interface NavBarProps {
  humBurgerMenuClick: () => void;
}

const NavBar = ({ humBurgerMenuClick }: NavBarProps) => {
  return (
    <AppBar component="nav">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={humBurgerMenuClick}
          sx={{ mr: 2, display: { sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>

        <Grid direction="row" container justifyContent="space-around">
          <Grid sx={{ display: { xs: "none", sm: "block" } }}>
            <TeckifyIcon />
          </Grid>
          <Grid alignContent="center">
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {navItems.map((item) => (
                <Button key={item} sx={{ color: "black" }}>
                  {item}
                </Button>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
