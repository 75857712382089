import { Suspense } from "react";
import { Outlet, createBrowserRouter } from "react-router-dom";
import App from "src/App";
import LinearLoader from "src/components/loading/LinearLoader";
import Progress from "src/components/loading/Progress";
import AuthLayout from "src/layouts/auth-layout";
import DashboardPageLayout from "src/layouts/dashboard-layout";
import LandingLayout from "src/layouts/landing-layout";
import Login from "src/pages/authentication/Login";
import Signup from "src/pages/authentication/Signup";
import Dashboard from "src/pages/dashboard/dashboard";
import ErrorPage from "src/pages/errors/ErrorPage";
import LandingPage from "src/pages/landing-page";
import paths, { rootPaths } from "./paths";

export const routes = [
  {
    element: (
      <Suspense fallback={<Progress />}>
        <App />
      </Suspense>
    ),
    children: [
      {
        path: paths.dashboard,
        element: (
          <DashboardPageLayout>
            <Suspense fallback={<LinearLoader />}>
              <Outlet />
            </Suspense>
          </DashboardPageLayout>
        ),
        children: [
          {
            index: true,
            element: <Dashboard />,
          },
        ],
      },
      {
        element: (
          <LandingLayout>
            <Suspense fallback={<LinearLoader />}>
              <Outlet />
            </Suspense>
          </LandingLayout>
        ),
        children: [
          {
            path: rootPaths.root,
            element: <LandingPage />,
          },
        ],
      },
      {
        element: <AuthLayout />,
        children: [
          {
            path: paths.login,
            element: <Login />,
          },
          {
            path: paths.signup,
            element: <Signup />,
          },
        ],
      },
      {
        path: "*",
        element: <ErrorPage />,
      },
    ],
  },
];

const router = createBrowserRouter(routes, { basename: "/" });

export default router;
