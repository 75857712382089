import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { TeckifyIcon } from "src/components/icons/teckify/teckify";

interface Props {
  isOpen: boolean;
  humBurgerMenuClick: () => void;
}

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];

const CustomDrawer = ({ isOpen, humBurgerMenuClick }: Props) => {
  return (
    <nav>
      <Drawer
        open={isOpen}
        onClose={humBurgerMenuClick}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
          },
        }}
      >
        <Box onClick={humBurgerMenuClick} sx={{ textAlign: "center" }}>
          <Typography variant="h6" sx={{ my: 2 }}>
            <TeckifyIcon />
          </Typography>
          <Divider />
          <List>
            {navItems.map((item) => (
              <ListItem key={item} disablePadding>
                <ListItemButton sx={{ textAlign: "center" }}>
                  <ListItemText primary={item} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </nav>
  );
};

export default CustomDrawer;
