import { SvgIcon, SvgIconProps } from '@mui/material';

const Blood = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
      <path
        d="M10.3835 2.61906C10.3187 2.54352 10.2243 2.5 10.1248 2.5C10.0254 2.5 9.93093 2.54352 9.86621 2.61906C9.62468 2.90149 4 9.61517 4 12.8272C4.018 14.4349 4.67287 15.9699 5.82117 17.0951C6.96948 18.2204 8.51724 18.8442 10.1249 18.8298C11.7326 18.8442 13.2804 18.2204 14.4287 17.0951C15.577 15.9699 16.2319 14.435 16.2499 12.8272C16.2499 9.61517 10.6252 2.90139 10.3837 2.61906H10.3835ZM12.3196 16.9447C12.2727 16.9701 12.2199 16.983 12.1665 16.982C12.0097 16.9811 11.8738 16.8732 11.8375 16.7207C11.8011 16.5681 11.8738 16.4104 12.0134 16.339C12.669 16.015 13.2216 15.5149 13.6092 14.8947C13.9969 14.2744 14.2042 13.5586 14.2081 12.8273C14.2081 12.6394 14.3604 12.4871 14.5484 12.4871C14.7363 12.4871 14.8886 12.6394 14.8886 12.8273C14.8852 13.6845 14.643 14.5237 14.1892 15.2511C13.7354 15.9783 13.0881 16.5647 12.3196 16.9446L12.3196 16.9447Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Blood;
