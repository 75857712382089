import { SvgIcon, SvgIconProps } from '@mui/material';

const Fencing = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        d="M18.0891 3.08958C18.4145 2.76414 18.4145 2.23651 18.0891 1.91107C17.7637 1.58563 17.236 1.58563 16.9106 1.91107L6.49392 12.3277C6.16848 12.6532 6.16848 13.1808 6.49392 13.5062C6.81935 13.8317 7.34699 13.8317 7.67243 13.5062L18.0891 3.08958Z"
        fill="currentColor"
      />
      <path
        d="M4.33909 16.8396C4.66453 16.5141 4.66453 15.9865 4.33909 15.6611C4.01366 15.3356 3.48602 15.3356 3.16058 15.6611L1.91058 16.9111C1.58514 17.2365 1.58514 17.7641 1.91058 18.0896C2.23602 18.415 2.76366 18.415 3.08909 18.0896L4.33909 16.8396Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.20573 16.1821C5.43677 16.4132 5.79478 16.5017 6.04865 16.296C6.42581 15.9904 6.6669 15.5235 6.6669 15.0003C6.6669 14.0799 5.92071 13.3337 5.00023 13.3337C4.47702 13.3337 4.01012 13.5748 3.70456 13.9519C3.49888 14.2058 3.58739 14.5638 3.81843 14.7948L5.20573 16.1821ZM6.01949 18.1749C5.38815 18.3775 4.73684 18.0703 4.268 17.6014L2.39913 15.7326C1.93029 15.2637 1.62307 14.6124 1.82562 13.9811C2.25627 12.6387 3.51478 11.667 5.00023 11.667C6.84118 11.667 8.33357 13.1594 8.33357 15.0003C8.33357 16.4858 7.36191 17.7443 6.01949 18.1749Z"
        fill="currentColor"
      />
      <path
        d="M1.91091 3.08958C1.58547 2.76414 1.58547 2.23651 1.91091 1.91107C2.23634 1.58563 2.76398 1.58563 3.08942 1.91107L13.5061 12.3277C13.8315 12.6532 13.8315 13.1808 13.5061 13.5062C13.1806 13.8317 12.653 13.8317 12.3276 13.5062L1.91091 3.08958Z"
        fill="currentColor"
      />
      <path
        d="M15.6609 16.8396C15.3355 16.5141 15.3355 15.9865 15.6609 15.6611C15.9863 15.3356 16.514 15.3356 16.8394 15.6611L18.0894 16.9111C18.4149 17.2365 18.4149 17.7641 18.0894 18.0896C17.764 18.415 17.2363 18.415 16.9109 18.0896L15.6609 16.8396Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7943 16.1821C14.5632 16.4132 14.2052 16.5017 13.9513 16.296C13.5742 15.9904 13.3331 15.5235 13.3331 15.0003C13.3331 14.0799 14.0793 13.3337 14.9998 13.3337C15.523 13.3337 15.9899 13.5748 16.2954 13.9519C16.5011 14.2058 16.4126 14.5638 16.1816 14.7948L14.7943 16.1821ZM13.9805 18.1749C14.6119 18.3775 15.2632 18.0703 15.732 17.6014L17.6009 15.7326C18.0697 15.2637 18.3769 14.6124 18.1744 13.9811C17.7437 12.6387 16.4852 11.667 14.9998 11.667C13.1588 11.667 11.6664 13.1594 11.6664 15.0003C11.6664 16.4858 12.6381 17.7443 13.9805 18.1749Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Fencing;
