export const grey = {
  50: "#F5F4F6",
  100: "#F5F5F5",
  200: "#F3F5F8",
  300: "#B1B5C3",
  400: "#777E90",
  500: "#7C7C8D",
  600: "#72767C",
  700: "#5F6165",
  800: "#242731",
  900: "#1F2128",
  A100: "#E6EBF8",
  A200: "#808191",
  A400: "#E6E8EC",
  A700: "#F3F3F3",
};

export const blue = {
  50: "#E3ECF1",
  300: "#2196f3",
  500: "#2884FF",
  800: "#4776D0",
};

export const purple = {
  50: "#B37EFC",
  300: "#A66FF0",
  500: "#A162F7",
};

export const red = {
  50: "#F4E3E5",
  300: "#FF7E86",
  500: "#FF5E6F",
  800: "#FF7E07",
};

export const orange = {
  50: "#E1DFA4",
  300: "#EF9011",
  500: "#FF764C",
  800: "#ef6c00",
  900: "#F6CC0D",
};
