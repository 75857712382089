import SendIcon from "@mui/icons-material/Send";
import { Box, Button, InputBase, Link, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FC } from "react";
import { FaFacebook, FaLinkedin, FaWhatsapp } from "react-icons/fa";
import { TeckifyIcon } from "src/components/icons/teckify/teckify";

const Footer: FC = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#F6F7F7",
        borderRadius: 10,
        px: { xs: 4, md: 8 },
        textAlign: "center",
        py: 4,
      }}
    >
      <Grid container spacing={4} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={4}>
          <Typography color="text.secondary" mb={2}>
            Send us your email
          </Typography>
          <Grid container spacing={1} justifyContent="center">
            <Grid item xs={12} sm={8}>
              <InputBase
                sx={{
                  backgroundColor: "background.paper",
                  borderRadius: 3,
                  width: "100%",
                  height: 48,
                  px: 2,
                }}
                placeholder="Enter your Email Address"
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button
                fullWidth
                variant="outlined"
                endIcon={<SendIcon />}
                sx={{ height: 48, color: "black", background: "#FEF1D4" }}
              >
                Send
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} md={4}>
          <TeckifyIcon height={100} width={100} />
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Link href="#" underline="none" sx={{ color: "black" }}>
                Academy
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" underline="none" sx={{ color: "black" }}>
                MVP Service
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" underline="none" sx={{ color: "black" }}>
                Quality Code
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" underline="none" sx={{ color: "black" }}>
                On time Delivery
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="center" py={2}>
        <Grid item>
          <Link href="#" underline="none" sx={{ color: "black" }}>
            <FaFacebook />
          </Link>
        </Grid>
        <Grid item>
          <Link href="#" underline="none" sx={{ color: "black" }}>
            <FaLinkedin />
          </Link>
        </Grid>
        <Grid item>
          <Link href="#" underline="none" sx={{ color: "black" }}>
            <FaWhatsapp />
          </Link>
        </Grid>
      </Grid>

      <Typography color="text.secondary" mt={4}>
        © 2024 Teckify. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
