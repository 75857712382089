import { SvgIcon, SvgIconProps } from '@mui/material';

const Facebook = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M12 21.5114C12 21.7877 12.2241 22.013 12.4999 21.9994C17.7905 21.7388 22 17.3642 22 12.0059C22 6.47977 17.5228 2 12 2C6.47715 2 2 6.47977 2 12.0059C2 16.6337 5.13989 20.5277 9.40433 21.6713C9.70966 21.7533 10 21.5158 10 21.1995V14.007H9C8.44771 14.007 8 13.5591 8 13.0064C8 12.4538 8.44772 12.0059 9 12.0059H10V10.0047C10 8.34687 11.3431 7.00293 13 7.00293H14C14.5523 7.00293 15 7.45091 15 8.00352C15 8.55613 14.5523 9.0041 14 9.0041H13C12.4477 9.0041 12 9.45208 12 10.0047V12.0059H14C14.5523 12.0059 15 12.4538 15 13.0064C15 13.5591 14.5523 14.007 14 14.007H12V21.5114Z"
        fill="white"
      />
    </SvgIcon>
  );
};

export default Facebook;
