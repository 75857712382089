import axios, { AxiosRequestConfig } from "axios";
import { BASE_URL } from "src/routes/paths";

export interface ApiResponse<T> {
  data: T;
}
export class ApiRequest {
  public async request<T>(config: AxiosRequestConfig): Promise<T> {
    const response: ApiResponse<T> = await axios(config);
    return response.data;
  }

  public getBaseUrl(): string {
    return `${BASE_URL}/api/v1`;
  }
}
