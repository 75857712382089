import { Grid } from "@mui/material";
import Cars from "src/components/sections/dashboard/cars/Cars";
import Factors from "src/components/sections/dashboard/factors/Factors";
import ReminderTable from "src/components/sections/dashboard/ReminderTable";
import Statistics from "src/components/sections/dashboard/statistics/Statistics";
import { cars } from "src/data/dashboard/cars";
import { factors } from "src/data/dashboard/factors";

const Dashboard = () => {
  return (
    <Grid container rowGap={3.75}>
      <Grid item xs={12}>
        <Factors factors={factors} />
      </Grid>

      <Grid item xs={12}>
        <Statistics />
      </Grid>

      <Grid item xs={12}>
        <Cars cars={cars} />
      </Grid>

      <Grid item xs={12}>
        <ReminderTable />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
