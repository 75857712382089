import { Grid, Stack, Typography } from "@mui/material";
import Marquee from "react-fast-marquee";
import AwsIcon from "src/components/icons/teckify/aws";
import FlutterIcon from "src/components/icons/teckify/flutter";
import JavaIcon from "src/components/icons/teckify/java";
import ReactIcon from "src/components/icons/teckify/react";

const technology = [
  {
    title: "React",
    icon: <ReactIcon height={80} width={80} />,
  },
  {
    title: "AWS",
    icon: <AwsIcon height={80} width={80} />,
  },
  {
    title: "Flutter",
    icon: <FlutterIcon height={80} width={80} />,
  },
  {
    title: "JAVA",
    icon: <JavaIcon height={80} width={80} />,
  },
  {
    title: "React",
    icon: <ReactIcon height={80} width={80} />,
  },
  {
    title: "AWS",
    icon: <AwsIcon height={80} width={80} />,
  },
  {
    title: "Flutter",
    icon: <FlutterIcon height={80} width={80} />,
  },
  {
    title: "JAVA",
    icon: <JavaIcon height={80} width={80} />,
  },
  {
    title: "React",
    icon: <ReactIcon height={80} width={80} />,
  },
  {
    title: "AWS",
    icon: <AwsIcon height={80} width={80} />,
  },
  {
    title: "Flutter",
    icon: <FlutterIcon height={80} width={80} />,
  },
  {
    title: "JAVA",
    icon: <JavaIcon height={80} width={80} />,
  },
];

const Technology = () => {
  return (
    <Stack sx={{ py: { xs: 8, md: 10 } }}>
      <Grid container justifyContent="center" sx={{ padding: 4 }}>
        <Typography sx={{ color: "text.secondary" }} variant="h3">
          {/* Technology we used */}
        </Typography>
      </Grid>
      <Grid>
        <Marquee style={{ borderRadius: 10 }} speed={80} gradient={true}>
          {technology.map((item) => (
            <Grid
              container
              justifyContent="center"
              mx={10}
              direction="column"
              alignItems="center"
            >
              {item.icon}
              <Typography sx={{ color: "black" }} variant="h6">
                {item.title}
              </Typography>
            </Grid>
          ))}
        </Marquee>
      </Grid>
    </Stack>
  );
};

export default Technology;
