import { AxiosRequestConfig } from "axios";
import { useMutation, UseMutationResult } from "react-query";

import {
  CHANGE_PASSWORD,
  LOGIN,
  LOGOUT,
  REGISTER,
  RESET_PASSWORD,
  SEND_OTP,
} from "src/apiservice/apiPath";
import { ApiRequest } from "src/lib/axios";
import { ApiError, LoginResponse, LogoutResponse } from "./type";

const axios = new ApiRequest();

export const useLoginMutation = (): UseMutationResult<
  LoginResponse,
  ApiError,
  { email: string; password: string }
> => {
  return useMutation((loginData: { email: string; password: string }) => {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${axios.getBaseUrl()}/${LOGIN}`,
      data: loginData,
    };
    const response = axios.request<LoginResponse>(config);
    return response;
  });
};

export const useLogoutMutation = (): UseMutationResult<
  LogoutResponse,
  ApiError,
  { accessToken: string }
> => {
  return useMutation((data: { accessToken: string }) => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${data.accessToken}`,
    };
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${axios.getBaseUrl()}/${LOGOUT}`,
      headers: headers,
    };

    const response = axios.request<LogoutResponse>(config);
    return response;
  });
};

export const useChangePasswordMutation = () => {
  return useMutation(
    (loginData: {
      name: string;
      email: string;
      password: string;
      newPassword: string;
    }) => {
      const config: AxiosRequestConfig = {
        method: "POST",
        url: `${axios.getBaseUrl()}/${CHANGE_PASSWORD}`,
        data: loginData,
      };
      return axios.request(config);
    }
  );
};

export const useRegisterMutation = () => {
  return useMutation((loginData: { name: string; email: string }) => {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${axios.getBaseUrl()}/${REGISTER}`,
      data: loginData,
    };
    return axios.request(config);
  });
};

export const useResetPasswordMutation = () => {
  return useMutation(
    (loginData: {
      verificationCode: string;
      email: string;
      password: string;
    }) => {
      const config: AxiosRequestConfig = {
        method: "POST",
        url: `${axios.getBaseUrl()}/${RESET_PASSWORD}`,
        data: loginData,
      };
      return axios.request(config);
    }
  );
};

export const useSendOtpMutation = () => {
  return useMutation((loginData: { email: string }) => {
    const config: AxiosRequestConfig = {
      method: "POST",
      url: `${axios.getBaseUrl()}/${SEND_OTP}`,
      data: loginData,
    };
    return axios.request(config);
  });
};
