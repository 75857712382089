export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const STAGE = process.env.REACT_APP_STAGE;

export const rootPaths = {
  root: "/",
  pagesRoot: "/pages",
  errorRoot: "/error",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  dashboard: `${rootPaths.pagesRoot}/dashboard`,
  login: `/login`,
  signup: `/signup`,
};
