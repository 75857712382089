export const barChart = {
  day: [120, 80, 157, 60, 130, 40, 90],
  week: [80, 90, 120, 70, 160, 60, 96],
  month: [150, 80, 157, 80, 130, 70, 100],
};

export const areaChart = {
  day: [500, 620, 932, 801, 934, 1290, 1130, 1020, 1300, 1200],
  week: [80, 90, 120, 70, 160, 60, 90, 1000, 800, 900],
  month: [70, 90, 900, 130, 70, 90, 900, 500, 220, 100],
};
