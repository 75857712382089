import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import { FC, Fragment } from "react";
import GraphicsIcon from "src/components/icons/teckify/graphics";
import MobileIcon from "src/components/icons/teckify/mobile";
import WebIcon from "src/components/icons/teckify/web";

const services = [
  {
    logo: <WebIcon width={100} height={100} />,
    title: "Web Design & Development",
    description: `
      Transforming ideas into digital masterpieces. Our custom-designed, responsive websites ensure a seamless user experience across all devices. Boost your online presence with our SEO-optimized and secure web solutions.
      - Custom-designed, responsive websites
      - SEO-optimized and secure solutions
      - Scalable designs for all business sizes
    `,
  },
  {
    logo: <MobileIcon width={100} height={100} />,
    title: "Mobile App Development",
    description: `
      Creating intuitive and engaging mobile applications. Our user-centric designs and cross-platform solutions ensure your app stands out in the crowded market. Seamless integration, performance optimization, and ongoing support.
      - User-centric, engaging designs
      - Cross-platform solutions
      - Performance optimization and support
    `,
  },
  {
    logo: <GraphicsIcon width={100} height={100} />,
    title: "Graphics Design & Development",
    description: `
      Crafting visually stunning and impactful designs. Our creative expertise and high-quality visuals help build a strong and memorable brand identity. Innovative solutions delivered on time.
      - Visually stunning, impactful designs
      - High-quality, memorable brand visuals
      - Innovative solutions delivered promptly
    `,
  },
];

const ServiceCard: FC = () => {
  return (
    <Box sx={{ backgroundColor: "background.paper" }}>
      <Stack>
        <Box
          sx={{
            backgroundColor: "#F6F7F7",
            borderRadius: 10,
            py: { xs: 4, md: 10 },
            px: { xs: 4, md: 8 },
            textAlign: "center",
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            sx={{ mb: 1, fontSize: { xs: 32, md: 42 } }}
          >
            Services we are providing
          </Typography>
          <Typography sx={{ mb: 6 }} variant="body2">
            We offer best services with minimum price
          </Typography>

          <Grid container justifyContent="center">
            {services.map((service, index) => (
              <Grid mx={5} item key={index}>
                <Card sx={{ maxWidth: 345, background: "#F6F7F7" }} key={index}>
                  <CardActionArea>
                    <CardMedia
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 140,
                      }}
                    >
                      {service.logo}
                    </CardMedia>
                    <CardContent>
                      <Typography gutterBottom variant="h2" component="div">
                        {service.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontSize: 18, textAlign: "left" }}
                      >
                        {service.description.split("\n").map((line, idx) => (
                          <Fragment key={idx}>
                            <br />
                            {line}
                          </Fragment>
                        ))}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Stack>
    </Box>
  );
};

export default ServiceCard;
