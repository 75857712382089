import { SvgIcon, SvgIconProps } from '@mui/material';

const Calendar = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.8332 4.99967H4.1665C3.70627 4.99967 3.33317 5.37277 3.33317 5.83301V15.833C3.33317 16.2932 3.70627 16.6663 4.1665 16.6663H15.8332C16.2934 16.6663 16.6665 16.2932 16.6665 15.833V5.83301C16.6665 5.37277 16.2934 4.99967 15.8332 4.99967ZM4.1665 3.33301C2.78579 3.33301 1.6665 4.4523 1.6665 5.83301V15.833C1.6665 17.2137 2.78579 18.333 4.1665 18.333H15.8332C17.2139 18.333 18.3332 17.2137 18.3332 15.833V5.83301C18.3332 4.4523 17.2139 3.33301 15.8332 3.33301H4.1665Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.33333 10C7.8731 10 7.5 10.3731 7.5 10.8333C7.5 11.2936 7.8731 11.6667 8.33333 11.6667H14.1667C14.6269 11.6667 15 11.2936 15 10.8333C15 10.3731 14.6269 10 14.1667 10H8.33333ZM5.83333 13.3333C5.3731 13.3333 5 13.7064 5 14.1667C5 14.6269 5.3731 15 5.83333 15H10.8333C11.2936 15 11.6667 14.6269 11.6667 14.1667C11.6667 13.7064 11.2936 13.3333 10.8333 13.3333H5.83333Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.83333 1.66699C5.3731 1.66699 5 2.04009 5 2.50033V5.83366C5 6.2939 5.3731 6.66699 5.83333 6.66699C6.29357 6.66699 6.66667 6.2939 6.66667 5.83366V2.50033C6.66667 2.04009 6.29357 1.66699 5.83333 1.66699ZM14.1667 1.66699C13.7064 1.66699 13.3333 2.04009 13.3333 2.50033V5.83366C13.3333 6.2939 13.7064 6.66699 14.1667 6.66699C14.6269 6.66699 15 6.2939 15 5.83366V2.50033C15 2.04009 14.6269 1.66699 14.1667 1.66699Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Calendar;
