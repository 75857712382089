import { SvgIcon, SvgIconProps } from '@mui/material';

const SignOut = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.19107 12.5003C9.65098 12.4829 10.0379 12.8417 10.0552 13.3016C10.0944 14.3384 10.1494 15.0951 10.2034 15.6377C10.2566 16.172 10.5792 16.4935 11.0289 16.5485C11.5591 16.6133 12.307 16.6663 13.3334 16.6663C14.3598 16.6663 15.1078 16.6133 15.638 16.5485C16.0874 16.4935 16.4101 16.1719 16.4634 15.6374C16.5637 14.6303 16.6667 12.8904 16.6667 9.99967C16.6667 7.10899 16.5637 5.36902 16.4634 4.3619C16.4101 3.82745 16.0874 3.50581 15.638 3.45087C15.1078 3.38605 14.3598 3.33301 13.3334 3.33301C12.307 3.33301 11.5591 3.38605 11.0289 3.45086C10.5792 3.50583 10.2566 3.82732 10.2034 4.36165C10.1494 4.90422 10.0944 5.66095 10.0552 6.69778C10.0379 7.15769 9.65098 7.51644 9.19107 7.49908C8.73116 7.48172 8.3724 7.09481 8.38976 6.6349C8.42994 5.57071 8.4869 4.7792 8.54495 4.19643C8.667 2.9713 9.52117 1.95609 10.8266 1.79651C11.4343 1.72223 12.2507 1.66634 13.3334 1.66634C14.4161 1.66634 15.2325 1.72223 15.8402 1.79652C17.1458 1.95612 17.9998 2.9718 18.1218 4.1967C18.2293 5.27524 18.3334 7.0734 18.3334 9.99967C18.3334 12.9259 18.2293 14.7241 18.1218 15.8027C17.9998 17.0275 17.1458 18.0432 15.8402 18.2028C15.2325 18.2771 14.4161 18.333 13.3334 18.333C12.2507 18.333 11.4343 18.2771 10.8266 18.2028C9.52117 18.0433 8.667 17.0281 8.54495 15.8029C8.4869 15.2201 8.42994 14.4286 8.38976 13.3644C8.3724 12.9045 8.73116 12.5176 9.19107 12.5003Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.00576 12.3274C6.3312 12.6528 6.3312 13.1805 6.00576 13.5059C5.68032 13.8314 5.15269 13.8314 4.82725 13.5059L1.91058 10.5893C1.58514 10.2638 1.58514 9.73618 1.91058 9.41074L4.82725 6.49408C5.15269 6.16864 5.68032 6.16864 6.00576 6.49408C6.3312 6.81951 6.3312 7.34715 6.00576 7.67259L4.51168 9.16667H12.4998C12.9601 9.16667 13.3332 9.53976 13.3332 10C13.3332 10.4602 12.9601 10.8333 12.4998 10.8333L4.51168 10.8333L6.00576 12.3274Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default SignOut;
