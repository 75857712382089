export const LOGIN_SUCCESS_MESSAGE = "Login Succeeded";
export const LOGOUT_SUCCESS_MESSAGE = "Logout Succeeded";

export const RESET_PASSWORD_SUCCESS_MESSAGE = "Password reset success";
export const OTP_SENT_MESSAGE = "Check your email to get the otp";
export const CHANGED_PASSWORD_MESSAGE = "Password changed successfully!!";
export const TEMPORARY_PASSWORD_SENT_MESSAGE =
  "A temporary password sent to your email please reset your password while login";

export const PLEASE_WAIT_MESSAGE = "Please wait...";
export const ACCESS_TOKEN_MESSAGE = "AccessToken";
