import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FC, Fragment } from "react";
import DeliveryManagementIcon from "src/components/icons/teckify/delivery-management";
import EcommerceManagementIcon from "src/components/icons/teckify/ecommerce-management";
import EmployeeManagementIcon from "src/components/icons/teckify/employee-management";
import InventoryManagementIcon from "src/components/icons/teckify/Inventory-management";
import SalesManagementIcon from "src/components/icons/teckify/sales-management";
import StudentManagementIcon from "src/components/icons/teckify/student-management";

const services = [
  {
    logo: <SalesManagementIcon width={100} height={100} />,
    title: "Primary & Secondary Sales Management",
    description: `
      Automate sales, manage leads, track interactions, streamline
      workflows, boost productivity, optimize performance, improve
      reporting
    `,
  },
  {
    logo: <EmployeeManagementIcon width={100} height={100} />,
    title: "Employee Management",
    description: `
      Manage personnel, recruit, onboard, develop policies, handle
      payroll, administer benefits, foster engagement, ensure
      compliance
    `,
  },
  {
    logo: <EcommerceManagementIcon width={100} height={100} />,
    title: "E-Commerce",
    description: `
      Online selling, digital transactions, virtual storefronts,
      global reach, secure payments, customer experience, products
      management
    `,
  },
  {
    logo: <DeliveryManagementIcon width={100} height={100} />,
    title: "Product Delivery Management",
    description: `
      Order online, delivery services, restaurant partnerships, track
      orders, contactless delivery, diverse cuisine, convenience,
      customer satisfaction
    `,
  },
  {
    logo: <InventoryManagementIcon width={100} height={100} />,
    title: "Inventory Management",
    description: `
      Track stock, optimize supply chain, minimize costs, forecast
      demand, automate processes, monitor levels, streamline
      operations, reduce wastage
    `,
  },
  {
    logo: <StudentManagementIcon width={100} height={100} />,
    title: "Student Management System",
    description: `
      Administer courses, schedule classes, manage student records,
      oversee faculty, handle admissions, coordinate events, ensure
      compliance
    `,
  },
];

const Specialization: FC = () => {
  return (
    <Box sx={{ backgroundColor: "background.paper", py: { xs: 8, md: 10 } }}>
      <Box>
        <Box
          sx={{
            backgroundColor: "white",
            borderRadius: 10,
            py: { xs: 4, md: 10 },
            textAlign: "center",
          }}
        >
          <Typography
            variant="h1"
            component="h2"
            sx={{ mb: 1, fontSize: { xs: 32, md: 42 } }}
          >
            Our Expertise
          </Typography>
          <Typography sx={{ mb: 6 }} variant="body2">
            These are the software that we are specialized in
          </Typography>

          <Grid container justifyContent="center" gap={10}>
            {services.map((service, index) => (
              <Grid mx={5} item key={index}>
                <Card
                  sx={{ maxWidth: 345, background: "#F6F7F7"}}
                  key={index}
                >
                  <CardActionArea>
                    <CardMedia
                      component="div"
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: 140,
                      }}
                    >
                      {service.logo}
                    </CardMedia>
                    <CardContent>
                      <Typography gutterBottom variant="h2" component="div">
                        {service.title}
                      </Typography>
                      <Typography
                        variant="body2"
                        color="text.secondary"
                        sx={{ fontSize: 18, textAlign: "left" }}
                      >
                        {service.description.split("\n").map((line, idx) => (
                          <Fragment key={idx}>
                            <br />
                            {line}
                          </Fragment>
                        ))}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default Specialization;
