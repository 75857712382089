import { SvgIcon, SvgIconProps } from '@mui/material';

const Icon1 = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M8.5 6H6.7C8.2 4.7 10 4 12 4C12.3 4 12.6 4 12.9 4.1C13.4 4.2 13.9 3.8 14 3.2C14.1 2.7 13.7 2.2 13.1 2.1C12.7 2 12.4 2 12 2C9.6 2 7.3 2.9 5.5 4.4V3C5.5 2.4 5.1 2 4.5 2C3.9 2 3.5 2.4 3.5 3V7C3.5 7.6 3.9 8 4.5 8H8.5C9.1 8 9.5 7.6 9.5 7C9.5 6.4 9.1 6 8.5 6ZM7 14.5C6.4 14.5 6 14.9 6 15.5V17.3C4.7 15.8 4 14 4 12C4 11.7 4 11.4 4.1 11.1C4.2 10.6 3.8 10.1 3.2 10C2.7 9.9 2.2 10.3 2.1 10.9C2 11.3 2 11.6 2 12C2 14.4 2.9 16.7 4.4 18.5H3C2.4 18.5 2 18.9 2 19.5C2 20.1 2.4 20.5 3 20.5H7C7.3 20.5 7.6 20.3 7.8 20.1C7.8 20 7.9 19.9 7.9 19.8C7.9 19.7 7.9 19.7 7.9 19.6V19.5V15.5C8 14.9 7.6 14.5 7 14.5ZM21 5.5C21.6 5.5 22 5.1 22 4.5C22 3.9 21.6 3.5 21 3.5H17C16.9 3.5 16.9 3.5 16.8 3.5C16.7 3.5 16.6 3.6 16.5 3.6C16.4 3.7 16.3 3.7 16.3 3.8C16.3 3.9 16.2 4 16.2 4C16.2 4.1 16.2 4.1 16.2 4.2V4.3V8.3C16.2 8.9 16.6 9.3 17.2 9.3C17.8 9.3 18.2 8.9 18.2 8.3V6.7C19.5 8.1 20.2 10 20.2 12C20.2 12.3 20.2 12.6 20.1 12.9C20 13.4 20.4 13.9 21 14H21.1C21.6 14 22 13.6 22.1 13.1C22.1 12.7 22.2 12.4 22.2 12C22.2 9.6 21.3 7.3 19.8 5.5H21ZM20.3 16.5C20.2 16.4 20.1 16.3 20 16.2C19.9 16.1 19.8 16.1 19.7 16.1H19.6H19.5H15.5C14.9 16.1 14.5 16.5 14.5 17.1C14.5 17.7 14.9 18.1 15.5 18.1H17.3C15.9 19.4 14 20.1 12 20.1C11.7 20.1 11.4 20.1 11.1 20C10.6 19.9 10.1 20.3 10 20.9C9.9 21.5 10.3 21.9 10.9 22C11.3 22 11.6 22.1 12 22.1C14.4 22.1 16.7 21.2 18.5 19.7V21C18.5 21.6 18.9 22 19.5 22C20.1 22 20.5 21.6 20.5 21V17C20.5 16.8 20.4 16.6 20.3 16.5Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Icon1;
