import { SvgIcon, SvgIconProps } from '@mui/material';

const Search = (props: SvgIconProps) => {
  return (
    <SvgIcon width="21" height="20" viewBox="0 0 21 20" fill="none" {...props}>
      <path
        d="M20.1191 18.3129L16.4113 14.6329C17.8505 12.8373 18.5475 10.5582 18.3589 8.26415C18.1704 5.97016 17.1105 3.83564 15.3974 2.2995C13.6843 0.76336 11.448 -0.0576363 9.14852 0.00532409C6.84901 0.0682845 4.66099 1.01042 3.03438 2.638C1.40777 4.26558 0.466194 6.45489 0.403271 8.75578C0.340348 11.0567 1.16086 13.2942 2.69608 15.0084C4.23131 16.7225 6.36456 17.783 8.65719 17.9717C10.9498 18.1604 13.2276 17.463 15.0221 16.0229L18.6999 19.7029C18.7928 19.7966 18.9034 19.871 19.0252 19.9217C19.1469 19.9725 19.2776 19.9987 19.4095 19.9987C19.5414 19.9987 19.6721 19.9725 19.7938 19.9217C19.9156 19.871 20.0262 19.7966 20.1191 19.7029C20.2992 19.5164 20.3999 19.2672 20.3999 19.0079C20.3999 18.7485 20.2992 18.4993 20.1191 18.3129ZM9.41544 16.0229C8.0318 16.0229 6.67922 15.6123 5.52876 14.8431C4.3783 14.074 3.48163 12.9807 2.95213 11.7016C2.42263 10.4226 2.28409 9.01509 2.55403 7.65722C2.82396 6.29935 3.49025 5.05207 4.46864 4.07311C5.44702 3.09414 6.69356 2.42745 8.05062 2.15736C9.40768 1.88726 10.8143 2.02588 12.0926 2.5557C13.371 3.08551 14.4636 3.98272 15.2323 5.13386C16.001 6.28501 16.4113 7.63838 16.4113 9.02285C16.4113 10.8794 15.6742 12.6598 14.3622 13.9726C13.0503 15.2854 11.2709 16.0229 9.41544 16.0229Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Search;
