import { ICar } from 'src/types/types';


const carImages: Record<number, string> = { 1: "undefined", 2: "undefined", 3: "undefined" };

export const cars: ICar[] = [
  {
    id: 1,
    imageUrl: carImages[1],
    recommendation: 64,
    modelName: 'Mini Cooper',
    mileage: 132,
    costPerHour: 32,
    backgroundColor: 'warning.lighter',
  },
  {
    id: 2,
    recommendation: 74,
    imageUrl: carImages[2],
    modelName: 'Porsche 911 Carrera',
    mileage: 130,
    costPerHour: 28,
    backgroundColor: 'primary.lighter',
  },
  {
    id: 3,
    recommendation: 74,
    imageUrl: carImages[3],
    modelName: 'Porsche 911 Carrera',
    mileage: 130,
    costPerHour: 28,
    backgroundColor: 'error.lighter',
  },
];
