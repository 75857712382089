import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import { PropsWithChildren } from "react";
import CustomDrawer from "./drawer/drawer";
import Footer from "./footer/footer";
import NavBar from "./navBar/navBar";

const LandingLayout = ({ children }: PropsWithChildren) => {
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const humBurgerMenuClick = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <Stack direction="row">
      <CssBaseline />
      <NavBar humBurgerMenuClick={humBurgerMenuClick} />
      <CustomDrawer
        isOpen={mobileOpen}
        humBurgerMenuClick={humBurgerMenuClick}
      />
      <Box component="main" sx={{ width: "100vw", height: "100vh" }}>
        <Toolbar />
        {children}
        <Footer />
      </Box>
    </Stack>
  );
};

export default LandingLayout;
