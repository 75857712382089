import { SvgIcon, SvgIconProps } from '@mui/material';

const Doughnut = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99984 18.3337C14.6022 18.3337 18.3332 14.6027 18.3332 10.0003C18.3332 5.39795 14.6022 1.66699 9.99984 1.66699C5.39746 1.66699 1.6665 5.39795 1.6665 10.0003C1.6665 14.6027 5.39746 18.3337 9.99984 18.3337ZM15.2663 14.0884C16.1439 12.9595 16.6665 11.541 16.6665 10.0003C16.6665 6.60064 14.1218 3.79532 10.8332 3.38524V6.77201C12.2709 7.14204 13.3332 8.44713 13.3332 10.0003C13.3332 10.6185 13.1649 11.1974 12.8716 11.6937L15.2663 14.0884ZM14.0878 15.2669L11.6931 12.8722C11.1968 13.1654 10.618 13.3337 9.99984 13.3337C9.3817 13.3337 8.80285 13.1654 8.30661 12.8722L5.91187 15.2669C7.04076 16.1444 8.45928 16.667 9.99984 16.667C11.5404 16.667 12.9589 16.1444 14.0878 15.2669ZM6.6665 10.0003C6.6665 10.6185 6.8348 11.1974 7.12807 11.6937L4.73334 14.0884C3.85576 12.9595 3.33317 11.541 3.33317 10.0003C3.33317 6.60064 5.87791 3.79532 9.1665 3.38524V6.77201C7.72881 7.14204 6.6665 8.44713 6.6665 10.0003ZM9.99984 11.667C10.9203 11.667 11.6665 10.9208 11.6665 10.0003C11.6665 9.07985 10.9203 8.33366 9.99984 8.33366C9.07936 8.33366 8.33317 9.07985 8.33317 10.0003C8.33317 10.9208 9.07936 11.667 9.99984 11.667Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default Doughnut;
