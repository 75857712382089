import { SvgIcon, SvgIconProps } from '@mui/material';

const ShoppingBag = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7851 6.66667H5.21481C4.25344 6.66667 3.49142 7.4778 3.55139 8.4373L3.96806 15.104C4.02296 15.9824 4.75137 16.6667 5.63148 16.6667H14.3685C15.2486 16.6667 15.977 15.9824 16.0319 15.104L16.4486 8.4373C16.5085 7.4778 15.7465 6.66667 14.7851 6.66667ZM5.21481 5C3.29208 5 1.76803 6.62227 1.88797 8.54126L2.30464 15.2079C2.41444 16.9647 3.87127 18.3333 5.63148 18.3333H14.3685C16.1287 18.3333 17.5855 16.9647 17.6953 15.2079L18.112 8.54126C18.2319 6.62227 16.7079 5 14.7851 5H5.21481Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8335 5.83366C5.8335 3.53247 7.69898 1.66699 10.0002 1.66699C12.3013 1.66699 14.1668 3.53247 14.1668 5.83366V7.50032C14.1668 7.96056 13.7937 8.33366 13.3335 8.33366C12.8733 8.33366 12.5002 7.96056 12.5002 7.50032V5.83366C12.5002 4.45295 11.3809 3.33366 10.0002 3.33366C8.61945 3.33366 7.50016 4.45295 7.50016 5.83366V7.50032C7.50016 7.96056 7.12707 8.33366 6.66683 8.33366C6.20659 8.33366 5.8335 7.96056 5.8335 7.50032V5.83366Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};

export default ShoppingBag;
